import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'info',
    loadChildren: () => import('./info/info.module').then( m => m.InfoPageModule)
  },
  {
    path: 'fabbisogni-details',
    loadChildren: () => import('./fabbisogni-details/fabbisogni-details.module').then( m => m.FabbisogniDetailsPageModule)
  },
  {
    path: 'soluzioni-details',
    loadChildren: () => import('./soluzioni-details/soluzioni-details.module').then( m => m.SoluzioniDetailsPageModule)
  },
  {
    path: 'baby-sitter-calc',
    loadChildren: () => import('./calcolatori/baby-sitter-calc/baby-sitter-calc.module').then( m => m.BabySitterCalcPageModule)
  },
  {
    path: 'domestici-calc',
    loadChildren: () => import('./calcolatori/domestici-calc/domestici-calc.module').then( m => m.DomesticiCalcPageModule)
  },
  {
    path: 'psicologo-calc',
    loadChildren: () => import('./calcolatori/psicologo-calc/psicologo-calc.module').then( m => m.PsicologoCalcPageModule)
  },
  {
    path: 'contabilita-calc',
    loadChildren: () => import('./calcolatori/contabilita-calc/contabilita-calc.module').then( m => m.ContabilitaCalcPageModule)
  },
  {
    path: 'costo-casa-calc',
    loadChildren: () => import('./calcolatori/costo-casa-calc/costo-casa-calc.module').then( m => m.CostoCasaCalcPageModule)
  },
  {
    path: 'costo-studi-calc',
    loadChildren: () => import('./calcolatori/costo-studi-calc/costo-studi-calc.module').then( m => m.CostoStudiCalcPageModule)
  },  {
    path: 'soluzioni-popup',
    loadChildren: () => import('./soluzioni-popup/soluzioni-popup.module').then( m => m.SoluzioniPopupPageModule)
  },





];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
