import { Component } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  animations: [
    trigger(
      'fadeInOutAnimation',
      [
        transition(
          ':enter',
          [
            style({opacity: 0}),
            animate('1s ease-out',
                    style({opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({opacity: 1 }),
            animate('1s ease-in',
                    style({opacity: 0}))
          ]
        )
      ]
    ),
  ]
})
export class AppComponent {
  companyId = '';
  authToken = '';
  jwtDecoded;

  constructor() {
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    this.authToken = urlParams.get('AUTHTOKEN');

    this.getDecodedAccessToken(this.authToken);


    if(this.companyId === '')
    {
      this.companyId = urlParams.get('company_id');
    }

    if(this.companyId !== '1')
    {
      document.getElementById('company-theme').setAttribute('href','assets/styles/external.css');
    }

  }

  getDecodedAccessToken(token: string): any {
    try{
        this.jwtDecoded = jwt_decode(token);
        this.companyId = this.jwtDecoded.tool_company_id ? this.jwtDecoded.tool_company_id: '';
        return this.jwtDecoded;
    }
    catch(Error){
        return null;
    }
  }
}
